/*==========================================================================

		Template Elements Stylesheet
		____________________________________________________________

		Client:		ClientName
		Date:		Date
		Author:		Edge Marketing Solutions

===========================================================================*/

	/* [Global Variables] - http://sass-lang.com/guide */

	$color-blue-light: rgb(0, 176, 173);
	$color-blue-dark: rgb(0, 131, 154);
	$color-green: rgb(148, 201, 61);
	$color-grey: rgb(88, 89, 91);

	$font-default: 'Dosis', sans-serif;

/*=========================================================================*/

	/* [Global Template Styles] */

	body
	{
		color: $color-grey;
		font-family: $font-default;
		font-weight: 400;
		font-size: 1.1rem;
	}

	a
	{
		-webkit-transition: all 150ms ease-in-out;
		-moz-transition: all 150ms ease-in-out;
		-ms-transition: all 150ms ease-in-out;
		-o-transition: all 150ms ease-in-out;
		transition: all 150ms ease-in-out;

		color: $color-blue-light;
	}

		a:hover
		{
			color: $color-blue-dark;
		}

	h1
	{
		margin: 1.5rem 0 1.5rem 0;

		font-weight: 500;
		color: $color-blue-dark;
		font-size: 3.4rem;
	}

	h2
	{
		margin: 1.5rem 0;

		font-weight: 500;
		color: $color-blue-dark;
		font-size: 2.6rem;
	}

	h3
	{
		margin: 1.5rem 0;

		font-weight: 500;
		color: $color-blue-light;
		font-size: 2.6rem;
	}

	h4
	{
		margin: 1.5rem 0;

		font-weight: 500;
		color: $color-blue-dark;
		font-size: 1.9rem;
	}

	h5
	{
		margin: 1.5rem 0;

		font-weight: 500;
		color: $color-blue-light;
		font-size: 1.9rem;
	}

	h6
	{
		margin: 1.5rem 0 0.5rem 0;

		font-weight: 500;
		color: $color-blue-dark;
		font-size: 1.4rem;
	}

	img
	{
		max-width: 100%;
	}

	img.svg-responsive
	{
		width: 100% \9;
	}

	img.img-lightbox
	{
		cursor: zoom-in;
	}

	.btn
	{
		padding: 0.5rem 1.5rem;

		border-radius: 3rem;
		font-weight: 700;
	}

	.btn-primary
	{
		background-color: $color-green;
		background-image: url(../images/btn-bg-waves.svg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;

		border: none;
	}

		.btn-primary:hover
		{
			background-color: $color-blue-dark;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {

		//Bootstrap - hide only mobile
		.d-xs-none
		{
			display: none;
		}
	}

	/*=========================================================================*/

	/* [Header Template Styles] */

	.header
	{
		padding: 1.5rem 1rem;
	}

		.header .social
		{
			display: inline-block;
			padding: 0 1rem;

			font-size: 2.2rem;
			line-height: 0;
			vertical-align: bottom;
		}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.header .btn
		{
			display: block;
		}

		.header .social
		{
			margin-top: 1rem;
		}
	}

	/*=========================================================================*/

	/* [Navigation Template Styles] */

	.navbar-expand-md
	{
		padding: 0;
		margin-top: 1rem;
	}

	.navbar-light .navbar-nav
	{
		width: 100%;
		align-items: center;

		justify-content: space-around;
	}

	.navbar-light .navbar-nav .nav-link
	{
		color: $color-grey;
		font-weight: 500;
		text-align: center;
		line-height: 1.3rem;
	}

	.navbar-light .navbar-nav .show > .nav-link,
	.navbar-light .navbar-nav .active > .nav-link,
	.navbar-light .navbar-nav .nav-link.show,
	.navbar-light .navbar-nav .nav-link.active
	{
		color: $color-blue-light;
	}

	.navbar-light .navbar-toggler
	{
		width: 100%;
		margin: 1rem 0;

		border: 1px solid $color-blue-light;
	}

	.navbar-light .navbar-nav .dropdown-menu
	{
		text-align: center;
	}

	.navbar-light .dropdown-item.active,
	.dropdown-item:active
	{
		background-color: $color-blue-light;
	}

	/* All desktop */
	@media (min-width: 768px) {
		.navbar-light .navbar-nav .dropdown-menu
		{
			max-width: 300px;
			width: 230px;
			left: 50%;
			transform: translateX(-50%);

			-webkit-box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.2);
			box-shadow: 2px 2px 10px 1px rgba(0,0,0,0.2);
		}

		.navbar-light .dropdown-item
		{
			white-space: normal;
		}
	}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.navbar-light .navbar-nav .nav-link
		{
			font-size: 1.0rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.navbar-light .navbar-nav .nav-link
		{
			font-size: 1rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.navbar-expand-md
		{
			margin-top: 0rem;
		}

		.navbar-light .navbar-nav
		{
			display: block;

			text-align: center;
		}
	}

	/*=========================================================================*/

	/* [Page Template Styles] */

	.page
	{
		width: 100%;
		padding: 0 0 4rem 0;
	}

	.listing
	{
		width: 100%;
		margin-top: 2rem;
		display: flex;
		justify-content: stretch;
		flex-wrap: wrap;

		border: 1px solid transparentize($color-grey, 0.7);
		border-radius: 1.5rem;
	}

		.listing .listing-thumbnail
		{
			width: 30%;
			flex-basis: 30%;
			z-index: 200;

			border-radius: 1.5rem;
		}

			.listing .listing-thumbnail a
			{
				display: block;
				width: 100%;
				height: 100%;

				background-size: cover;
				background-position: center center;

				border-radius: 1.5rem;
			}

		.listing .listing-contents
		{
			flex-basis: 70%;
			width: 70%;
			z-index: 100;
			margin-bottom: 56px;

			padding: 2rem;
		}

			.listing .listing-contents h3
			{
				margin: 0 0 1.5rem 0;

				font-size: 1.6rem;
				color: $color-blue-dark;
			}

				.listing .listing-contents a
				{
					color: $color-blue-dark;
				}

				.listing .listing-contents p
				{
					margin: 0;

					font-size: 1.0rem;
					line-height: 1.4rem;
				}

		.listing .listing-action
		{
			width: 100%;
			padding: 0.5rem 0;
			display: block;
			flex-basis: 100%;
			margin-top: -56px;
			z-index: 1;

			background-color: $color-blue-light;

			border-radius: 0 0 1.5rem 1.5rem;
			color: rgb(255, 255, 255);
		}

			.listing .listing-action .wrapper
			{
				padding: 0 2rem;
				width: 70%;

				font-size: 1.6rem;
			}

			.listing .listing-action a
			{
				color: rgb(255, 255, 255);
			}

			.listing .listing-action a:hover
			{
				text-decoration: none;
				text-shadow: 0 0 10px #000000;
			}

	.sidebar .nav
	{
		background-color: rgba(223, 224, 224, 0.5);
		border-radius: 0 0 1.5rem 1.5rem;
	}

		.sidebar .nav h6
		{
			margin: 0;
			padding: 1rem 1rem;

			font-size: 1.1rem;
			font-weight: 700;

			a {
				color: $color-blue-dark;

				&:hover {
					text-decoration: none;
				}
			}
		}

		.sidebar .nav .nav-item
		{
			border-top: 1px solid rgb(255, 255, 255);
		}

		.sidebar .nav .nav-item.active .nav-link
		{
			color: $color-blue-light;
		}

		.sidebar .nav .nav-item .nav-link
		{
			color: $color-grey;
		}

		.sidebar .btn-primary
		{
			margin: 1rem;
			display: block;
		}

	.breadcrumbs
	{
		width: 100%;

		background-color: $color-green;
	}

		.breadcrumb
		{
			margin: 0;

			background-color: transparent;
			border: none;
			border-radius: 0;

			font-size: 0.9rem;
			color: rgb(255, 255, 255);
		}

		.breadcrumb-item.active,
		.breadcrumb-item a,
		.breadcrumb-item + .breadcrumb-item::before
		{
			color: rgb(255, 255, 255);
		}

	.subpage-banner
	{
		width: 100%;
		position: relative;
		z-index: 1;
	}

		.subpage-banner img
		{
			width: 100%;
		}

		.subpage-banner::before
		{
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;

			background-color: transparentize($color-blue-dark, 0.7);
		}

		.subpage-banner::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			background-image: url(../images/banner-waves-overlay.svg);
			background-repeat: no-repeat;
			background-size: 30% auto;
			background-position: center left;
		}

		.subpage-banner .page-logo
		{
			position: absolute;
			top: 50%;
			width: 100%;
			height: 60%;
			transform: translateY(-40%);
			z-index: 10;

			text-align: center;
		}

			.subpage-banner .page-logo img
			{
				max-height: 80%;
			}

		.banner-top
		{
			width: 100%;
			position: absolute;
			z-index: 100;
			top: 0;
			left: 0;
			height: 23px;

			background-image: url(../images/banner-top.svg);
			background-position: 0 -1px;
			background-repeat: no-repeat;
			background-size: cover;
		}

	.pricing-panel
	{
		border: 1px solid transparentize($color-grey, 0.7);
		border-radius: 1.5rem;

		font-size: 1.0rem;
		font-weight: 500;
	}

		.pricing-panel h3
		{
			margin: 0;
			padding: 0.75rem 1rem;

			background-color: $color-blue-light;
			border-radius: 1.5rem 1.5rem 0 0;

			font-size: 1.4rem;
			color: rgb(255, 255, 255);
		}

		.pricing-panel .panel-content
		{
			padding: 1rem;
		}

	.swiper-container
	{
		width: 100%;
	}

		.swiper-slide {
			height: 41vw;
		}


		.video-container::before,
		.swiper-slide .swiper-slide-image::before
		{
			content: ' ';
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			z-index: 1;

			background-color: transparentize($color-blue-light, 0.6);
		}

		.video-container::after,
		.swiper-slide .swiper-slide-image::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			background-image: url(../images/banner-waves-overlay.svg);
			background-repeat: no-repeat;
			background-size: 50% auto;
			background-position: center left;
		}

		.swiper-slide .swiper-text
		{
			position: absolute;
			z-index: 100;
			width: 100%;
			top: 50%;
			transform: translateY(-50%);

			color: rgb(255,255, 255);
			text-align: center;
			font-size: 5rem;
			line-height: 5rem;
			font-weight: 400;
		}

			.swiper-slide .swiper-text .title
			{
				font-weight: 700;
			}

		.swiper-pagination-bullet-active
		{
			background-color: $color-green;
		}

	.feature-panel
	{
		width: 100%;
		display: flex;
		padding: 2rem;
		flex-wrap: wrap;
		align-content: stretch;
		position: relative;
		z-index: 1;

		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
	}

		.feature-panel::before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-color: rgba(255, 255, 255, 0.8);
		}

		.feature-panel .featured-item
		{
			flex-basis: 33%;
			width: 33%;
			padding: 4rem;
			align-items: center;
			display: flex;
			justify-content: center;

			border-bottom: 2px solid $color-green;
			border-right: 2px solid $color-green;

			text-align: center;
		}

			.feature-panel .featured-item:hover
			{
				background-color: transparentize($color-green, 0.8);
			}

		.feature-panel .btn-book
		{
			background-color: $color-blue-dark;

			color: rgb(255, 255, 255);
			font-size: 3rem;
			font-weight: 700;
		}

			.feature-panel .btn-book:hover
			{
				background-color: $color-green;

				text-decoration: none;
			}

	.feature-image
	{
		position: relative;
		z-index: 1;
		box-sizing: border-box;
		width: 100%;

		-moz-transform: rotate(5deg);
		-webkit-transform: rotate(5deg);
		-o-transform: rotate(5deg);
		-ms-transform: rotate(5deg);
		transform: rotate(5deg);
	}

		.feature-image::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 100;

			background-image: url(../images/polaroid-overlay.svg);
			background-repeat: no-repeat;
			background-size: 100% 100%;
		}

		.feature-image img
		{
			padding: 10px;
		}

	.blog-homepage
	{
		width: 100%;
	}

		.blog-homepage h3
		{
			margin: 0;
			padding: 1rem 0;

			background-color: $color-blue-light;

			text-align: center;
			color: rgb(255, 255, 255);
			font-size: 2.2rem;
			font-weight: 400;
		}

		.blog-homepage h4
		{
			margin: 0;
			padding: 1rem 0;

			background-color: $color-blue-dark;

			text-align: center;
			color: rgb(255, 255, 255);
			font-size: 2.2rem;
			font-weight: 400;
		}

			.blog-homepage a
			{
				display: block;
				padding: 6rem 2rem;
				position: relative;
				z-index: 1;
				height: 100%;

				background-size: cover;
				background-repeat: no-repeat;

				text-align: center;
				color: rgb(255, 255, 255);
				font-size: 1.8rem;
			}

				.blog-homepage a::before
				{
					content: ' ';
					position: absolute;
					width: 100%;
					height: 100%;
					top: 0;
					left: 0;
					z-index: -1;

					-webkit-transition: all 150ms ease-in-out;
					-moz-transition: all 150ms ease-in-out;
					-ms-transition: all 150ms ease-in-out;
					-o-transition: all 150ms ease-in-out;
					transition: all 150ms ease-in-out;
					background-color: rgba(0, 0, 0, 0.5);
				}

				.blog-homepage a:hover
				{
					text-decoration: none;
				}

				.blog-homepage a:hover:before
				{
					background-color: rgba(0, 0, 0, 0.1);
				}

				.blog-homepage .fa
				{
					color: $color-green;
				}

	/* Large desktop */
	@media (min-width: 1200px) {
		.feature-panel .featured-item:nth-last-child(-n+3)
		{
			border-bottom: none;
		}

		.feature-panel .featured-item:nth-child(3n)
		{
			border-right: none;
		}
	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {
		.sidebar .nav .nav-item .nav-link
		{
			font-size: 1.0rem;
		}

		.feature-panel .featured-item:nth-last-child(-n+3)
		{
			border-bottom: none;
		}

		.feature-panel .featured-item:nth-child(3n)
		{
			border-right: none;
		}

		.swiper-slide .swiper-text
		{
			font-size: 3.5rem;
			line-height: 3.5rem;
		}

		.blog-homepage a
		{
			padding: 5rem 2rem;

			font-size: 1.6rem;
		}
	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {
		.listing .listing-thumbnail
		{
			width: 40%;
			flex-basis: 40%;
		}

		.listing .listing-contents
		{
			flex-basis: 60%;
			width: 60%;
			margin-bottom: 56px;

			padding: 1.5rem;
		}

		.listing .listing-action .wrapper
		{
			padding: 0 1.5rem;
			width: 60%;

			font-size: 1.4rem;
		}

		.subpage-banner .page-logo
		{
			height: 80%;
			transform: translateY(-35%);
		}

		.feature-panel .featured-item
		{
			flex-basis: 33%;
			width: 33%;
			padding: 3rem;
		}

			.feature-panel .featured-item img
			{
				max-height: 110px !important;
			}

		.swiper-slide .swiper-text
		{
			font-size: 3rem;
			line-height: 3rem;
		}

		.feature-panel .featured-item:nth-last-child(-n+3)
		{
			border-bottom: none;
		}

		.feature-panel .featured-item:nth-child(3n)
		{
			border-right: none;
		}

		.blog-homepage a
		{
			padding: 3rem 2rem;

			font-size: 1.2rem;
		}
	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.page
		{
			padding: 0 0.5rem 3rem 0.5rem;
		}

		.listing
		{
			display: block;
		}

		.listing .listing-thumbnail
		{
			width: 100%;
			height: 200px;
		}

		.listing .listing-contents
		{
			width: 100%;
			margin-bottom: 56px;

			padding: 1.5rem;
		}

		.listing .listing-action .wrapper
		{
			padding: 0 1.5rem;
			width: 100%;

			font-size: 1.2rem;
		}

		.subpage-banner .page-logo
		{
			display: none;
		}

		.banner-top
		{
			height: 10px;
			top: -1px;
		}

		.feature-panel .featured-item
		{
			flex-basis: 50%;
			width: 50%;
			padding: 2rem;
		}

		.feature-panel .featured-item:nth-last-child(-n+2)
		{
			border-bottom: none;
		}

		.feature-panel .featured-item:nth-child(2n)
		{
			border-right: none;
		}

		.feature-panel .featured-item img
		{
			max-height: 110px !important;
		}

		.swiper-slide .swiper-text
		{
			font-size: 1.5rem;
			line-height: 1.5rem;
		}

		.blog-homepage a
		{
			padding: 4rem 2rem;

			font-size: 1.4rem;
		}
	}

	/*=========================================================================*/

	/* [Footer Template Styles] */

	.footer-menu
	{
		width: 100%;
		padding: 2rem 1rem;
		position: relative;
		z-index: 1;

		background-image: url(../images/footer-bg-image.jpg);
		background-repeat: no-repeat;
		background-size: cover;

		color: rgb(255, 255, 255);
		font-size: 1.0rem;
	}

		.footer-menu::before
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-color: transparentize($color-blue-dark, 0.3);
		}

		.footer-menu::after
		{
			content: ' ';
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: -1;

			background-image: url(../images/footer-nav-waves.svg);
			background-repeat: no-repeat;
			background-size: 30% auto;
			background-position: center right;
		}

		.footer-menu a
		{
			color: rgb(255, 255, 255);
		}

		.footer-menu h6
		{
			margin: 0 0 0.5rem 0;

			color: rgb(255, 255, 255);
			font-size: 1.0rem;
			font-weight: 700;
		}

		.footer-menu .nav .nav-link
		{
			padding: 0.5rem 0;
		}

	.footer
	{
		width: 100%;
		padding: 3rem 1rem;

		background-color: $color-blue-light;

		color: rgb(255, 255, 255);
		font-size: 1.0rem;
	}

		.footer a
		{
			color: rgb(255, 255, 255);
		}

			.footer a:hover
			{
				text-decoration: none;
				color: $color-blue-dark;
			}

			.footer .btn-primary:hover
			{
				color: rgb(255, 255, 255);
			}

		.footer img
		{
			max-width: 290px;
		}

		.footer .social
		{
			font-size: 1.8rem;
		}

			.footer .social a
			{
				display: inline-block;
				margin: 0 0.1rem;
			}

	/* Large desktop */
	@media (min-width: 1200px) {

	}

	/* Desktop */
	@media (min-width: 992px) and (max-width: 1199px) {

	}

	/* Portrait tablet to landscape and desktop */
	@media (min-width: 768px) and (max-width: 991px) {

	}

	/* Landscape phone to portrait tablet */
	@media (max-width: 767px) {
		.footer-menu
		{
			text-align: center;
		}

			.footer-menu .nav .nav-link
			{
				padding: 0.25rem 0;
			}
		
		.footer
		{
			padding: 3rem 0.5rem;

			text-align: center;
		}

			.footer img
			{
				margin-bottom: 2rem;
			}
	
			.footer .social
			{
				margin: 2rem 0;
			}
	}

	/*=========================================================================*/

	/* [Table as row override Styles] */

	table.table-as-row > tbody > tr:before,
	table.table-as-row > tbody > tr:after {
		content: " ";
		display: table;
		clear: both;
	}

	table.table-as-row > tbody > tr {
		height: auto !important;
	}

	table.table-as-row,
	table.table-as-row > tbody,
	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
	}

	table.table-as-row > tbody > tr {
		display: block;
		width: auto;
		margin-right: -15px;
		margin-left: -15px;
	}

	table.table-as-row > tbody > tr > td[class*=col-] {
		display: block;
		height: auto !important;
		margin-bottom: 20px;
	}

	/* col-lg */
	@media (min-width: 1200px) {
		table.table-as-row > tbody > tr > td[class*=col-lg-] {
			float: left;
		}
	}

	/* col-md */
	@media (min-width: 992px) {
		table.table-as-row > tbody > tr > td[class*=col-md-] {
			float: left;
		}
	}

	/* col-sm */
	@media (min-width: 768px) {
		table.table-as-row > tbody > tr > td[class*=col-sm-] {
			float: left;
		}
	}

	/* col-xs */
	table.table-as-row > tbody > tr > td[class*=col-xs-] {
		float: left;
	}

	/*=========================================================================*/

	/* [Video Styles] */

	.video-container {
		position: absolute;
		top: 0;
		left: 0;
		height: 41vw;
		width: 100%;
		z-index: -1;
	}

	.video-foreground,
	.video-foreground iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
	}

	@media (min-aspect-ratio: 16/9) {
		.video-foreground {
			height: 300%;
			top: -100%;
		}
	}

	@media (max-aspect-ratio: 16/9) {
		.video-foreground {
			width: 300%;
			left: -100%;
		}
	}